import React from "react";

import { Container } from "@atoms";
import { Review } from "@molecules";

const ReviewGrid = ({ reviews }) => {
  return (
    <Container>
      <ul className="grid grid-cols-1 gap-6 md:grid-cols-3">
        {reviews.map(({ uid, ...r }) => (
          <li key={uid}>
            <Review {...r} />
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default ReviewGrid;
